<template>    
    <ValidationProvider  class="input-checkbox"  :rules="`${required ? 'required' : ''}`" v-slot="{ errors }">
            <label>
                <span v-if="!childFrom">{{order}}.</span> 
                <span v-html="label"></span>   
                <span class="input-required" v-if="required">*</span>
                <span v-if="createdBy" class="created-by"></span>
            </label>
            <div class="checkbox-item" v-for="(item, idx) in choices" :key="idx">
                <input
                    type="checkbox"
                    :class="[{ 'input-error' : errors[0]}, getColor(item.questions_choice_value, item.questions_choice_id)]"
                    :name="item.questions_choice_questions_id"
                    :value="item.questions_choice_id"
                    v-model="content"
                    @change="$emit('changeValue',  item.questions_choice_questions_id, content, item.display_child)"
                />
                <label :class="[{ 'label-created-by' : getCreatedBy(item.questions_choice_id)}, getColor(item.questions_choice_value, item.questions_choice_id)]">{{ JSON.parse(item.questions_choice_label)[currentLang]}} </label>                
            </div>
            <span class="notice-error">{{ errors[0] }}</span>
    </ValidationProvider>
   
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name : 'InputCheckbox',
    data() {
        return {
            content : []
        }
    },
    components : {
        ValidationProvider
    },
    props : ['id', 'required', 'items', 'order', 'choices', 'label', 'childFrom', 'parentFrom', 'value', 'createdBy'],
    methods : {
        getCreatedBy(id) {   
            if(this.content && this.content.includes(id.toString()) && this.createdBy) {
                return true
            }else {
                return false
            }
        },
        getColor(value, id) {
            if(this.content) {
                if(this.$store.state.CURRENT_MODULE === 1 && this.$store.state.USER.role != 4 &&  ( this.content.includes(id.toString()) || this.content.includes(id) )) {
                    if(value === 1) {
                        return 'green';
                    }else if (value === 2) {
                        return 'orange';
                    }else if (value === 3) {
                        return 'red';
                    }
                }
            }
        }
    },
    mounted() {
        if(this.value) {
            this.content = this.value.split(',')
        }

    },
    computed : {
        currentLang() {
            return this.$store.state.LANG
        }
    }
}
</script>

<style lang="scss" scoped>
    .input-checkbox {
        display: flex;
        flex-direction: column;
        margin:10px 0;
    }

    label {
        font-size:18px;
        color:$textColor;
        font-family:$fontMedium;
        margin-bottom: 5px;
        display:flex;
    }

    input {
        width:calc(100% - 40px);
        padding:10px 20px;
        background:$inputBackground;
        border-radius:10px;
        font-size:15px;
        color:$textColor; 
    }

    .checkbox-item {
        display: flex;
        margin:2px 0 2px 10px;
        align-items: center;

    }

    input[type="checkbox"] {
        width:18px;
        min-width:18px;
        height:18px;
        border-radius:2px;
        border:1px solid $mainColor;
        background: #fff;
        margin-right:20px;
        cursor: pointer;
        padding:0;
    }

    input[type="checkbox"]:checked {
        background: $mainColor;
    } 

    input[type="checkbox"].red:checked {
        background:$error;
    }

    input[type="checkbox"].green:checked {
        background:$success;
    }


    .checkbox-item label {
        font-family: $font;
        margin:0;
    }

    input[type="checkbox"].input-error {
        border-color:$error;
    }

    label.label-created-by {
        color:$mainColor;
    }

    label.red {
        color:$error;
        font-weight: 700;
    }

    label.green {
        color:$success;
        font-weight: 700;
    }

    label.orange {
        color:$mainColor;
        font-weight: 700;
    }

    @media screen and (max-width:768px) {
        label {
            font-size:15px;
        }
    }
</style>