<template>    
    <ValidationProvider  class="input-text"  :rules="`${required ? 'required' : ''}|max:100`" v-slot="{ errors }">
            <label :class="{'label-created-by' : createdBy}">
                <span v-if="!childFrom">{{order}}.</span>
                <span v-html="label"></span> 
                <span class="input-required" v-if="required">*</span>
            </label>
            <input
                type="text"
                :class="[{ 'input-error' : errors[0]}, { 'input-created-by' : createdBy }]"
                @keyup="$emit('changeValue',  id, content, true)"
                v-model="content"
            />
            <span class="notice-error">{{ errors[0] }}</span>
    </ValidationProvider>
   
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name : 'InputText',
    data() {
        return {
            content : null
        }
    },
    components : {
        ValidationProvider
    },
    props : ['id', 'required', 'value', 'order', 'label', 'childFrom', 'parentFrom', 'createdBy'],
    mounted() {
        this.content = this.value
    }
}
</script>

<style lang="scss" scoped>
    .input-text {
        display: flex;
        flex-direction: column;
        margin:10px 0;
    }

    label {
        font-size:18px;
        color:$textColor;
        font-family:$fontMedium;
        margin-bottom: 5px;
        display: flex;
    }

    input {
        width:calc(100% - 40px);
        padding:10px 20px;
        background:$inputBackground;
        border-radius:10px;
        font-size:15px;
        color:$textColor; 
    }

    label.label-created-by {
        color:$mainColor;
    }

    .input-created-by {
        border-color:$mainColor;
    }

    @media screen and (max-width:768px) {
        label {
            font-size:15px;
        }
    }
</style>