<template>
    <div class="pathology-sub-step-container">
        <div class="pathology-sub-step-item" v-for="(item, idx) in steps" :key="idx" @click="changeStep(item)" >
            <div :class="['pathology-sub-step', { active : $store.state.CURRENT_MODULE == item}, {'bloked' : $store.state.USER.role === 4}]">{{idx + 1}}</div>
            <div class="pathology-sub-step-line"></div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'PathologySubStep',
    props : [ 'steps'],
    methods : {
        changeStep(step) {
            if(this.$store.state.USER.role != 4){
                this.$store.commit('setCurrentModule', step)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.pathology-sub-step-container {
        display: flex;
        justify-content: center;
        margin:0 auto;
        padding:20px 0 40px 0;
        background:$mainColor;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .pathology-sub-step-item {
        padding:0 20px;
        position:relative;
    }

    .pathology-sub-step {
        height:70px;
        width:70px;
        border-radius:50%;
        display:flex;
        justify-content: center;
        align-items: center;
        border:3px solid #fff;
        font-size:17px;
        font-family: $fontBlack;
        color:#fff;
        cursor: pointer;
        transition: 0.5s;
        position: relative;
        background: $mainColor;
        z-index: 99;
        transition: 0.5s;
    }

    .pathology-sub-step.active,  .pathology-sub-step:hover{
        background:#fff;
        color:$mainColor;
    }

    .pathology-sub-step.bloked{
        pointer-events: none;
    }

    .pathology-sub-step-line {
        position: absolute;
        top:50%;
        left:0;
        right:0;
        height:2%;
        z-index: 9;
        background: #fff;
    }

    .pathology-sub-step-item:first-child .pathology-sub-step-line {
        left:50%;
    }

    .pathology-sub-step-item:last-child .pathology-sub-step-line {
        right:50%;
    }

    @media screen and (max-width:768px) {
        .pathology-sub-step {
            height:35px;
            width:35px;
            min-width: 35px;
        }
    }
</style>