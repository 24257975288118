<template>    
    <ValidationProvider  class="input-select"  :rules="`${required ? 'required' : ''}`" v-slot="{ errors }">   
            <div class="select-container">
                <label :class="{'label-created-by' : createdBy}">
                    <span v-if="!childFrom">{{order}}.</span>
                    <span v-html="label"></span> 
                    <span class="input-required" v-if="required">*</span>
                </label>
                <select
                    :name="id"
                    v-model="content"
                    :class="[{ 'input-error' : errors[0] } , {'input-created-by' : createdBy }]"
                    @change="changeValue"
                >
                    <option value=""></option>
                    <option 
                        v-for="(item, idx) in choices" :key="idx"
                        :value="item.questions_choice_id"
                     >
                            {{ JSON.parse(item.questions_choice_label)[currentLang] }}
                        </option>
                    
                </select>
            </div>                   
            
            <span class="notice-error">{{ errors[0] }}</span>
    </ValidationProvider>
   
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name : 'InputSelected',
    data() {
        return {
            content : null
        }
    },
    components : {
        ValidationProvider
    },
    props : ['id', 'required', 'order', 'label', 'choices', 'childFrom', 'parentFrom', 'value', 'createdBy'],
    methods: {
        changeValue(){
            let displayChild = this.choices.find(e => e.questions_choice_id === this.content).display_child
            this.$emit('changeValue', this.id, this.content, displayChild)
        }
    },
    mounted() {
        this.content = this.value
    },
    computed : {
        currentLang() {
            return this.$store.state.LANG
        }
    }
}
</script>

<style lang="scss" scoped>
    .input-select {
        display: flex;
        flex-direction: column;
        margin:10px 0;
        border-bottom:1px solid $inputBackground;
    }

    label {
        font-size:18px;
        color:$textColor;
        font-family:$fontMedium;
        margin-bottom: 5px;
        margin-right:20px;
    }

    select {
        width:200px;
        min-width: 200px;
        padding:10px 20px;
        background:$inputBackground;
        border-radius:10px;
        font-size:15px;
        color:$textColor; 
        font-family: $font;
        border:1px solid transparent;
    }

    select:focus {
        outline:none;
        border:1px solid $mainColor;
    }

    .select-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
    }

    option {
        font-size:15px;
        color:$textColor; 
        font-family: $font;
    }

    select.input-error {
        border:1px solid $error;
    }

    
    label.label-created-by {
        color:$mainColor;
    }

    .input-created-by {
        border-color:$mainColor;
    }

    @media screen and (max-width:768px) {
        label {
            font-size:15px;
        }

        .select-container {
            flex-direction: column;
            align-items: flex-start;
        }

        .select-container select {
            margin-top: 10px;
        }

        .input-select {
            border:none;
        }
    }

</style>