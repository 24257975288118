<template>
    <div class="pathology-form">
        <h2 v-if="moduleName" class="module-name">{{ JSON.parse(moduleName)[currentLang]}}</h2>
        <p class="module-desc" v-if="moduleDesc" v-html="JSON.parse(moduleDesc)[currentLang]"></p>
        <template v-if="$store.state.CURRENT_MODULE != 1 || (this.results && this.results.length > 0 || questionDisplay) || $store.state.USER.role === 3 || $store.state.USER.role === 2">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)" id="form" :class="{'disabled-edit' : $store.state.USER.role === 3}">
                    <div class="module-container" v-for="(group,idx) in questionsFilter" :key="idx">
                        <div class="group-container">
                            <h3 v-if="group.title" class="group-title">{{ JSON.parse(group.title)[currentLang] }}</h3>
                            <p  v-if="group.description" class="group-description">{{ JSON.parse(group.description)[currentLang] }}</p>
                            <div class="input-container" v-for="(item, idz) in group.questions" :key="idz">
                                <template v-if="item.type === 1 && ( item.questions_display || getResults(item.questions_id))">
                                    <InputText
                                        :required="!item.is_optional"
                                        :id="item.questions_id"
                                        :label="JSON.parse(item.label)[currentLang]"
                                        :order="item.questions_order"
                                        :value="getResults(item.questions_id)"
                                        :parentFrom="item.parent_from"
                                        :childFrom="item.child_from"
                                        v-on:changeValue="changeValue"
                                        :createdBy="createdBy(item.questions_id)"
                                    >
                                    </InputText>
                                </template>
                                <template v-if="item.type === 2 && ( item.questions_display || getResults(item.questions_id))" >
                                    <InputTextarea
                                        :required="!item.is_optional"
                                        :id="item.questions_id"
                                        :label="JSON.parse(item.label)[currentLang]"
                                        :order="item.questions_order"
                                        :value="getResults(item.questions_id)"
                                        :parentFrom="item.parent_from"
                                        :childFrom="item.child_from"
                                        v-on:changeValue="changeValue"
                                        :createdBy="createdBy(item.questions_id)"
                                    >
                                    </InputTextarea>

                                </template>
                                <template v-if="item.type === 3 && ( item.questions_display || getResults(item.questions_id))">
                                    <InputSelect
                                        :required="!item.is_optional"
                                        :id="item.questions_id"
                                        :items="[]"
                                        :order="item.questions_order"
                                        :value="getResults(item.questions_id)"
                                        :label="JSON.parse(item.label)[currentLang]"
                                        :choices="item.choiceList"
                                        v-on:changeValue="changeValue"
                                        :parentFrom="item.parent_from"
                                        :childFrom="item.child_from"
                                        :createdBy="createdBy(item.questions_id)"
                                    >
                                    </InputSelect>
                                </template>
                                <template v-else-if="item.type === 4 && ( item.questions_display || getResults(item.questions_id))">
                                    <InputRadio
                                        :required="!item.is_optional"
                                        :id="item.questions_id"
                                        :items="[]"
                                        :label="JSON.parse(item.label)[currentLang]"
                                        :order="item.questions_order"
                                        :value="getResults(item.questions_id)"
                                        :choices="item.choiceList"
                                        v-on:changeValue="changeValue"
                                        :parentFrom="item.parent_from"
                                        :childFrom="item.child_from"
                                        :createdBy="createdBy(item.questions_id)"
                                    >
                                    </InputRadio>
                                </template>
                                <template v-else-if="item.type ===  5 && ( item.questions_display || getResults(item.questions_id))">
                                    <InputCheckbox
                                        :required="!item.is_optional"
                                        :id="item.questions_id"
                                        :items="[]"
                                        :label="JSON.parse(item.label)[currentLang]"
                                        :order="item.questions_order"
                                        :value="getResults(item.questions_id)"
                                        :choices="item.choiceList"
                                        v-on:changeValue="changeValue"
                                        :parentFrom="item.parent_from"
                                        :childFrom="item.child_from"
                                        :createdBy="createdBy(item.questions_id)"
                                    >
                                    </InputCheckbox>

                                </template>
                            </div>
                            <div class="group-sep" v-if="group.id != 0"></div>
                        </div>
                    </div>
                    <template v-if="loading">
                        <div class="loader-questions-form">
                            <Loader></Loader>
                        </div>
                    </template>
                    <template v-else>
                        <div class="submit-row" v-if="steps && $store.state.USER.role === 4">
                            <template v-if="$store.state.CURRENT_MODULE != steps[steps.length -1]">
                                <input type="submit" class="btn" :value="$t('form.next')" />
                            </template>
                            <template v-else>
                                <input type="submit" class="btn" :value="$t('form.finish')" />
                            </template>
                        </div>
                        <div :class="['submit-float', {'stop-float' : scrolledToBottom}]" v-if="$store.state.USER.role === 2">
                            <input type="submit" class="btn" :value="$t('form.validate')" />
                        </div>
                    </template>
                </form>

            </ValidationObserver>
        </template>

        <template v-else>
            <div class="module-display">
                <p>{{$t('form.moduleDisplay') }}</p>
                <div class="module-display-btn-container">
                    <div class="btn" @click="questionDisplay = true">{{$t('form.moduleComplete') }}</div>
                    <div class="btn" @click="skip">{{$t('form.moduleSkip') }}</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import InputText from './../../components/fields/InputText'
import InputRadio from './../../components/fields/InputRadio'
import InputCheckbox from './../../components/fields/InputCheckbox'
import InputTextarea from './../../components/fields/InputTextarea'
import InputSelect from './../../components/fields/InputSelect'
import axios from 'axios'
import store from './../../store'
import router from './../../router'
import Loader from './../Loader'

export default {
    name :'PathologyQuestionsForm',
    props : ['period', 'steps'],
    components : {
        ValidationObserver,
        InputText,
        InputRadio,
        InputCheckbox,
        InputTextarea,
        InputSelect,
        Loader
    },
    data() {
        return {
            questions : null,
            results: null,
            scrolledToBottom: false,
            loading: false,
            moduleName : null,
            moduleDesc : null,
            questionDisplay : false
        }
    },
    computed : {
        questionsFilter() {
            if(this.questions) {
                let groups = this.questions.filter(e => e.modules_id === store.state.CURRENT_MODULE)
                let groupArr = []
                groups.forEach(function(e) {
                    if(!groupArr.find(el => el.id === e.questions_questions_group_id)){
                        let group = {
                            id : e.questions_questions_group_id,
                            title : e.title,
                            description : e.description,
                            questions : [] 
                        }                    
                        groupArr.push(group)
                    } 
                    groupArr.find(el => el.id === e.questions_questions_group_id).questions.push(e)
                });
                return  groupArr
            }else {
                return []
            }
        },
        currentLang() {
            return this.$store.state.LANG
        },
    },
    methods : {
        submit() {

            this.loading = true

            let form = document.getElementById('form');
            let formData = new FormData(form)
            let params = {
                module_id : parseInt(store.state.CURRENT_MODULE),
                periods_id : parseInt(this.period),
                monitoring_id : parseInt(this.$route.params.id),
                results : []
            }

            let questionsArr = [];

            let vm = this

            formData.forEach(function(value, key) { 
                if(!questionsArr.includes(key)) {
                    questionsArr.push(key)
                    let updated_by = vm.questions.find(e => e.questions_id == key).updated_by
                    let obj  = {
                        key : key,
                        value : value,
                        updated_by : updated_by
                    }
                    params.results.push(obj)
                }else {
                    params.results.find(e => e.key === key).value += ',' + value
                }

            });

            this.questions.forEach(function(e){
                if( ( e.type === 1 || e.type === 2)  && e.questions_value)  {
                    let obj  = {
                        key : e.questions_id,
                        value : e.questions_value,
                        updated_by : e.updated_by
                    }
                    params.results.push(obj)
                }
            })

            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }         

             axios
                .post(store.getters.getURL +"results", params, config)
                .then(function (response) {
                    vm.loading = false
                    if(response.status == 200) {
                        if(store.state.USER.role === 2) {
                            store.dispatch('addNotif', {type : 1, content : 22})
                            vm.$emit('refreshResults')
                        }else {
                            //redirection nextStep si pas dernier module
                            if(store.state.CURRENT_MODULE != vm.steps[vm.steps.length -1]) {
                                let currentIndex = vm.steps.findIndex(e => e === store.state.CURRENT_MODULE)
                                store.commit('setCurrentModule', vm.steps[currentIndex + 1])
                            }else {
                                store.dispatch('addNotif', {type : 1, content : 23})
                                router.push({ name : 'PathologyList'})
                            }
                        }
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                })
                .catch((err) => {
                    vm.loading = false
                    store.dispatch('addNotif', {type : 2, content : err})
                });


    
        },
        skip() {
            let currentIndex = this.steps.findIndex(e => e === store.state.CURRENT_MODULE)
            store.commit('setCurrentModule', this.steps[currentIndex + 1])

        },
        changeValue(question, value, questionDisplay) {
            let questionFind = this.questions.find(e => e.questions_id === question)
            questionFind.questions_value = value 
            questionFind.updated_by = store.state.USER.role

           
            /* Condition question 8 module 4 de Tendon d'Achiille */
            if(question === 109) {

                let first = this.results.find( f => f.questions === 110)
                if(first) {
                    first.results = null
                }
                let second = this.results.find( f => f.questions === 111)
                if(second) {
                    second.results = null
                }
                let  third = this.results.find( f => f.questions === 112)
                if(third) {
                    third.results = null
                }

                let config = {
                    validateStatus: () => true,
                }
                if(store.getters.getToken) {
                    config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
                }

                let params = {
                    questions: [110,111,112],
                    monitoring_id : this.$route.params.id,
                    period : this.period
                }

                axios
                    .post(store.getters.getURL +"results/delete", params, config)     
                    .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                    });

                this.questions.find(e => e.questions_id == 110).questions_display = false
                this.questions.find(e => e.questions_id == 111).questions_display = false
                this.questions.find(e => e.questions_id == 112).questions_display = false
                this.questions.find(e => e.questions_id == 110).value = null
                this.questions.find(e => e.questions_id == 111).value = null
                this.questions.find(e => e.questions_id == 112).value = null
                
               if( value == 693) {
                    this.questions.find(e => e.questions_id == 110).questions_display = true
               }
               if ( value == 694) {
                   this.questions.find(e => e.questions_id == 111).questions_display = true
               }
               if ( value == 695) {
                   this.questions.find(e => e.questions_id == 112).questions_display = true
               }
            }
            if(questionFind.parent_from) {
                let child = this.questions.filter(e => e.child_from === question )
                if(questionDisplay ) {
                    child.forEach(e => e.questions_display = true)
                }else {
                    let vm = this
                    let questionsArr = []
                    child.forEach(function(e){
                        e.questions_display = false;
                        e.questions_value = null;
                        vm.results.filter( f => f.questions === e.questions_id).forEach( r => r.results = null)
                        questionsArr.push(e.questions_id)

                    })
                    let config = {
                        validateStatus: () => true,
                    }
                    if(store.getters.getToken) {
                        config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
                    }

                    let params = {
                        questions: questionsArr,
                        monitoring_id : this.$route.params.id,
                        period : this.period
                    }

                    axios
                        .post(store.getters.getURL +"results/delete", params, config)     
                        .catch((err) => {
                            store.dispatch('addNotif', {type : 2, content : err})
                        });
                } 
            }
            //si checkbox et que la valeur est vide on delete
            if(questionFind.type == 5 && value .length == 0) {
                let config = {
                    validateStatus: () => true,
                }
                if(store.getters.getToken) {
                    config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
                }

                let params = {
                    questions: [ questionFind.questions_id ],
                    monitoring_id : this.$route.params.id,
                    period : this.period
                }

                axios
                    .post(store.getters.getURL +"results/delete", params, config)     
                    .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                    });
            }

        },
        getData() {
            this.questions = null
            this.results = null
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .get(store.getters.getURL +"monitoring/get_forms/" + this.$route.params.id + "/" +  this.period + "/" + store.state.CURRENT_MODULE, config)
                .then(function (response) {
                    if(response.status === 200) {
                        vm.questions = response.data.questions
                        vm.results = response.data.results
                        vm.moduleDesc = response.data.module_desc
                        vm.moduleName = response.data.module_name
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        },
        getResults(question) {
            let results = this.results.find( e => e.questions === question)
            if(results) {
                return results.results
            }else {
                return null
            }
        },
        createdBy(id) {
            let createBy = this.results.find( e => e.questions === id)
            if(createBy && createBy.edited_by === 2) {
                return true
            }else {
                return false
            }
        },
        scroll () {
            window.onscroll = () => {
                let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 40 > document.documentElement.offsetHeight

                if (bottomOfWindow) {
                    this.scrolledToBottom = true 
                }else{
                    this.scrolledToBottom = false
                }
            }
        }

    },
    watch : {
        '$store.state.CURRENT_MODULE' : function() {
            this.getData();
        }
    },
    mounted () {
        this.scroll()
        this.getData();
    }
  

}
</script>

<style lang="scss" scoped>
    h2 {
        text-align: center;
        color:$mainColor;
        text-transform: uppercase;
    }

    h3.group-title {
        color:$mainColor;
        text-transform: uppercase;
        text-align: center;
        font-size:25px;
        font-weight:700;
        margin-bottom:20px;
    }

    .group-sep {
        margin:40px auto;
        width:80%;
        height:2px;
        background: $mainColor;
    }

    .pathology-form form {
        max-width: 1280px;
        width:90%;
        margin:0 auto;
    }

    .submit-row, .loader-questions-form {
        display: flex;
        justify-content: flex-end;
    }

    .submit-float{
        position:fixed;
        bottom:0;
        background:#fff;
        left:0;
        right:0;
        display: flex;
        justify-content: center;
        padding:5px;
        box-shadow: 0 0 5px rgba(0,0,0,0.5);
        z-index:999;
    }

    .submit-float.stop-float {
        bottom:40px;
        box-shadow: none;
        background: transparent;
    }

    .module-display-btn-container {
        display:flex;
        justify-content: space-around;
    }

    .module-display p {
        text-align:center;
        color:$mainColor;
        font-size:20px;
    }

    .module-display {
        margin:40px 0;
    }

    .module-name {
        color:#fff;
        background: $mainColor;
        font-size:25px;
        
    }

    .module-desc {
        margin:0 0 20px 0;
        padding:20px 40px;
        color:#fff;
        background: $mainColor;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        font-size:18px;
        text-align: justify;
    }

    .group-description {
        text-align: justify;
    }

    .module-display-btn-container .btn {
        padding-right:10px;
        padding-left:10px;
    }

    @media screen and (max-width:768px) {
        .module-name {
            font-size:20px;
        }

        .module-desc {
            font-size:15px;
            padding:20px;
        }

        .module-display-btn-container {
            flex-direction: column;
        }

        .module-display-btn-container .btn {
            margin:5px 0;
        }

        h3.group-title {
            font-size:18px;
        }

        .pathology-form form {
            width:100%;
        }
        
    }

</style>