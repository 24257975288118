<template>
    <div class="pathology-form">
         <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)" :class="['injection-form', { 'form-disabled' : $store.state.USER.role != 2 } ]">
                <div class="row col100">
                    <ValidationProvider  class="input-default"   rules="required" v-slot="{ errors }">
                        <label>{{$t('form.injection.date')}}</label>
                        <input type="date" :class="{ 'input-error' : errors[0]}"  v-model="injectionDate"  :placeholder=" $t('form.injection.date')" />
                        <span class="notice-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="row last col100">
                    <ValidationProvider  class="input-default"  rules="max:10000" v-slot="{ errors }">
                        <label>{{$t('form.injection.indications')}}</label>
                        <textarea type="text" :class="{ 'input-error' : errors[0]}"  rows="3" v-model="indications" ></textarea>
                        <span class="notice-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="sep-injection"></div>
                <div class="row">
                    <div class="col50">
                        <h3>{{ $t('form.injection.title1')}}</h3>
                        <ValidationProvider  class="input-default"  rules="required|max:100" v-slot="{ errors }">
                            <label>{{$t('form.injection.factPlaque')}}</label>
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="factPlaque" :placeholder=" $t('form.injection.factPlaque')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider  class="input-default"  rules="required|max:100" v-slot="{ errors }">
                            <label>{{$t('form.injection.factLeuco')}}</label>
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="factLeuco" :placeholder=" $t('form.injection.factLeuco')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider  class="input-default"  rules="required|max:100" v-slot="{ errors }">
                            <label>{{$t('form.injection.rendement')}}</label>
                            <input type="text"  :class="{ 'input-error' : errors[0]}"  v-model="rendement" :placeholder=" $t('form.injection.rendement')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
   
                    </div>
                    <div class="col50">
                         <h3>{{ $t('form.injection.title2')}}</h3>   
                        <ValidationProvider  class="input-default"  rules="required|max:100" v-slot="{ errors }">
                            <label>{{$t('form.injection.volume')}}</label>
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="injectionVolume" :placeholder=" $t('form.injection.volume')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider  class="input-default"  rules="required|max:100" v-slot="{ errors }">
                            <label>{{$t('form.injection.dose')}}</label>
                            <input type="text"  :class="{ 'input-error' : errors[0]}"  v-model="dose" :placeholder=" $t('form.injection.dose')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider  class="input-default"  rules="required|max:100" v-slot="{ errors }">
                            <label>{{$t('form.injection.compoPlaquette')}}</label>
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="compoPlaquette" :placeholder=" $t('form.injection.compoPlaquette')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider  class="input-default"  rules="required|max:100" v-slot="{ errors }">
                            <label>{{$t('form.injection.compoRedGlob')}}</label>
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="compoRedGlob" :placeholder=" $t('form.injection.compoRedGlob')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider  class="input-default"  rules="required|max:100" v-slot="{ errors }">
                            <label>{{$t('form.injection.compoLeuco')}}</label>
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="compoLeuco" :placeholder=" $t('form.injection.compoLeuco')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>


                       
                    </div>
                </div>
                <div class="sep-injection"></div>
                <div class="row col100">
                  <ValidationProvider  class="input-default"  rules="required|max:100" v-slot="{ errors }">
                        <label>{{$t('form.injection.douleur')}}</label>
                        <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="pain" :placeholder=" $t('form.injection.douleur')" />
                        <span class="notice-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="row col100">
                    <ValidationProvider  class="input-default"  rules="required|max:100" v-slot="{ errors }">
                        <label>{{$t('form.injection.classification')}}</label>
                        <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="classification" :placeholder=" $t('form.injection.classification')" />
                        <span class="notice-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="row col100">
                    <ValidationProvider  class="input-default input-file"  rules="mimes:application/pdf|size" v-slot="{ errors }">
                        <label>{{$t('form.injection.pdf')}}</label>
                        <template v-if="pdfFileName && pdfFileId">
                            <div class="pdf-file"  >
                                <span @click="downloadPDF">{{ pdfFileName }}</span>
                                <div class="delete-pdf" @click="deletePdf" v-if="$store.state.USER.role === 2"></div>
                            </div>
                        </template>
                        <template v-else >
                            <template v-if="$store.state.USER.role === 2">
                                <input type="file" ref="file" @change="handleFileUpload()" :class="{ 'input-error' : errors[0]}" />
                                <span class="notice-error">{{ errors[0] }}</span>
                            </template>
                        </template>
                    </ValidationProvider>
                </div>
                <div class="sep-injection"></div>
                <div class="row last col100">
                    <ValidationProvider  class="input-default"  rules="max:10000" v-slot="{ errors }">
                        <label>{{$t('form.injection.otherInjection')}}</label>
                        <textarea type="text" :class="{ 'input-error' : errors[0]}"  rows="8" v-model="otherInjection" ></textarea>
                        <span class="notice-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="row-submit" v-if="$store.state.USER.role === 2">
                    <template v-if="id">
                        <input type="submit" class="input-submit btn" :value="$t('admin.form.updateBtn')" /> 
                    </template>
                    <template v-else>
                        <input type="submit" class="input-submit btn" :value="$t('admin.form.validateBtn')" />   
                    </template>
                </div>             

            </form>
         </ValidationObserver>
        
        <ModalConfirm
            v-if="displayModalConfirm"
            :contentModal="$t('admin.form.confirmDeleteFile')"
            v-on:close="closeConfirm"
            v-on:confirm="confirmDelete"
        >
        </ModalConfirm>
        <div class="modal" v-if="loading">
            <Loader></Loader>
        </div>
    </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import store from './../../store';
import axios from 'axios';
import ModalConfirm from './../ModalConfirm'
import Loader from './../Loader'

export default {
    name : 'FormInjection',
     components : {
      ValidationProvider,
      ValidationObserver,
      ModalConfirm,
      Loader
    },
    data() {
        return {
            injectionDate : null,
            injectionVolume : null,
            factPlaque : null,
            factLeuco : null,
            rendement : null,
            dose : null,
            compoPlaquette : null,
            compoRedGlob : null,
            compoLeuco : null,
            classification : null,
            pdfFileName : null,
            pdfFileId : null,
            pain : null,
            otherInjection : null,
            indications : null,
            id : null,
            displayModalConfirm : false,
            loading :false
        }
    },
    methods : {
        submit() {
            let params = {
                injectionDate : this.injectionDate,
                injectionVolume : this.injectionVolume,
                factPlaque : this.factPlaque,
                factLeuco :  this.factLeuco,
                rendement : this.rendement,
                dose : this.dose,
                compoPlaquette : this.compoPlaquette,
                compoRedGlob : this.compoRedGlob,
                compoLeuco : this.compoLeuco,
                pain : this.pain,
                classification : this.classification,
                pdfFileId : this.pdfFileId,
                pathology_monitoring : this.$route.params.id,
                other_injection : this.otherInjection,
                indications : this.indications
            }
            
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            if(this.id) {
                params.id = this.id
                axios
                    .put(store.getters.getURL +"injection", params, config)
                    .then(function (response) {
                        if(response.status === 200) {
                            store.dispatch('addNotif', {type : 1, content : 18})
                        }else {
                            store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                        }
                        
                    })
                    .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                    });
            }else {
                let vm = this
                axios
                    .post(store.getters.getURL +"injection", params, config)
                    .then(function (response) {
                        if(response.status === 200) {
                            vm.id = response.data.id
                            store.dispatch('addNotif', {type : 1, content : 17})
                        }else {
                            store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})

                        }
                        
                    })
                    .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                    });
            }

        },
        handleFileUpload() {
            let pdf = this.$refs.file.files[0]
            let formData = new FormData();
            formData.append('file', pdf);  
            formData.append('id', this.$route.params.id);  

            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}`, 'content-type': 'multipart/form-data' }
            }

            let vm = this
            this.loading = true
            axios
                .post(store.getters.getURL+'injection/upload_pdf', formData , config)
                .then(function(response) {
                    if(response.status === 200) {   
                        vm.pdfFileName = response.data.name 
                        vm.pdfFileId =  response.data.id 
                        vm.loading = false                        
                    }else {
                        vm.pdfFile = null
                        vm.pdfFileId = null
                         store.dispatch('addNotif', {type : 2, content : 20})
                         vm.loading = false
                    }
                })
                .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                        vm.loading = false
                    });
        },
        downloadPDF() {
            let config = {
                validateStatus: () => true,
                responseType: 'blob',
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this
             axios
                .get(store.getters.getURL+'injection/download_pdf/' + this.pdfFileId ,  config)
                .then(function(response) {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', vm.pdfFileName);
                    document.body.appendChild(fileLink);
                
                    fileLink.click();
                })
        },
        deletePdf() {
            this.displayModalConfirm = true;
        },
        closeConfirm() {
            this.displayModalConfirm = false
        },
        confirmDelete() {
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .delete(store.getters.getURL+'injection/destroy_pdf/' + this.pdfFileId , config)
                .then(function(response) {
                    if(response.status === 200) {   
                        store.dispatch('addNotif', {type : 1, content : 21}) 
                        vm.pdfFileName = null
                        vm.pdfFileId =  null 
                        vm.displayModalConfirm = false                        
                    }
                })
                .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                    });
        }
    },
    mounted() {
           
        let config = {
            validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"injection/" + this.$route.params.id, config)
            .then(function (response) {
                if(response.status === 200 ) {
                    if(response.data.injection) {
                        vm.injectionDate = response.data.injection.injection_date
                        vm.injectionVolume = response.data.injection.injection_volume
                        vm.factPlaque = response.data.injection.injection_fact_plaque
                        vm.factLeuco = response.data.injection.injection_fact_leucocyte
                        vm.rendement = response.data.injection.injection_rendement_plaque
                        vm.dose = response.data.injection.injection_dose_injected
                        vm.compoPlaquette = response.data.injection.injection_compo_plaque
                        vm.compoRedGlob = response.data.injection.injection_compo_red_globule
                        vm.compoLeuco = response.data.injection.injection_compo_leucocyte
                        vm.classification = response.data.injection.injection_classification
                        vm.pain = response.data.injection.injection_pain
                        vm.otherInjection = response.data.injection.injection_other_injection
                        vm.indications = response.data.injection.injection_indications
                        vm.pdfFile =  response.data.injection.injection_file_path
                        vm.id = response.data.injection.injection_id
                        vm.pdfFileName = response.data.injection.file_name
                        vm.pdfFileId = response.data.injection.file_id
                        
                    }
                }else {
                    store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                }
                
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
}
</script>

<style lang="scss" scoped>



    .injection-form {
        max-width: 1200px;
        width: 90%;
        margin:80px auto 0 auto;
    }

    .injection-form.form-disabled input,  .injection-form.form-disabled textarea {
        pointer-events: none;
        opacity:0.5;
    }

    .modal {
        z-index:999;
    }

    .injection-form .row .col50, .injection-form .row .col100 {
        margin : 0 2%;
    }

    .input-default.input-file input {
        padding:13px 20px 12px 20px;
    }

    .row-submit {
        display:flex;
        justify-content: center;
        margin:20px auto;
    }

    .injection-form label {
        margin-left:5px;
    }

    .pdf-file {
        text-decoration: underline;
        display:flex;
        justify-content: space-between;
        margin-top:15px;
        margin-left:5px;
        align-items: center;
    }

    .pdf-file span {
        cursor: pointer;
    }

    .delete-pdf {
        height:25px;
        width:25px;
        background: url('./../../assets/icon-delete.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .row.last {
        margin-bottom: 20px;;
    }

    h3 {
        margin: 20px 0 20px 0;
        color:$mainColor;
        font-size:22px;
        font-weight:400;
    }

    .sep-injection {
        margin: 40px auto;
        width: 80%;
        height: 2px;
        background: #f7a850;
    }


    @media screen and (max-width:768px) {
        .pathology-form .row {
            flex-direction: column;
        }

        .pathology-form .row .col50, .pathology-form .row .col100 {
            width: 100%;
        }

        .pathology-form {
            padding-bottom: 40px;
        }
    }
</style>