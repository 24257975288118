<template>    
    <ValidationProvider  class="input-radio"  :rules="`${required ? 'required' : ''}`" v-slot="{ errors }">
            <label>
                <span v-if="!childFrom">{{order}}.</span> 
                <span v-html="label"></span> 
                <span class="input-required" v-if="required">*</span>
            </label>
            <div class="radio-item" v-for="(item, idx) in choices" :key="idx">
                <input
                    type="radio"
                    :class="[getColor(item.questions_choice_value, item.questions_choice_id), { 'input-error' : errors[0]}]"
                    :name="item.questions_choice_questions_id"
                    :value="item.questions_choice_id"
                    v-model="content"
                    @change="$emit('changeValue',  item.questions_choice_questions_id, content, item.display_child)"
                />
                <label :class="[ { 'label-created-by' : getCreatedBy(item.questions_choice_id)}, getColor(item.questions_choice_value, item.questions_choice_id)]">{{JSON.parse(item.questions_choice_label)[currentLang]}}</label>                
            </div>
            <span class="notice-error">{{ errors[0] }}</span>
    </ValidationProvider>
   
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name : 'InputRadio',
    data() {
        return {
            content : null
        }
    },
    components : {
        ValidationProvider
    },
    props : ['id', 'required', 'items', 'order', 'label', 'choices', 'childFrom', 'parentFrom', 'value', 'createdBy'],
    methods : {
        getCreatedBy(id) {
            if(this.content) {
                if(this.content == id && this.createdBy) {
                    return true
                }else {
                    return false
                }
            }else {
                return false
            }
       
        },
        getColor(value, id) {
            if(this.content) {
                if(this.$store.state.CURRENT_MODULE === 1 && this.$store.state.USER.role != 4 && this.content == id) {
                    if(value === 1) {
                        return 'green';
                    }else if (value === 2) {
                        return 'orange';
                    }else if (value === 3){
                        return 'red';
                    }
                }
            }
        }
    },
    mounted() {
        this.content = this.value
    },
    computed : {
        currentLang() {
            return this.$store.state.LANG
        }
    }
}
</script>

<style lang="scss" scoped>
    .input-radio {
        display: flex;
        flex-direction: column;
        margin:10px 0;
    }

    label {
        font-size:18px;
        color:$textColor;
        font-family:$fontMedium;
        margin-bottom: 5px;
    }

    input {
        width:calc(100% - 40px);
        padding:10px 20px;
        background:$inputBackground;
        border-radius:10px;
        font-size:15px;
        color:$textColor; 
    }

    .radio-item {
        display: flex;
        margin:2px 0 2px 10px;
        align-items: center;

    }

    input[type="radio"] {
        width:18px;
        min-width:18px;
        height:18px;
        border-radius:50%;
        border:1px solid $mainColor;
        background: #fff;
        margin-right:20px;
        cursor: pointer;
        padding:0;
    }

    input[type="radio"]:checked {
        background: $mainColor;
    } 

    input[type="radio"].red:checked {
        background: $error;
    }

    input[type="radio"].green:checked {
        background: $success;
    }

 

    .radio-item label {
        font-family: $font;
        margin:0;
    }

    input[type="radio"].input-error {
        border-color:$error;
    }

    label.label-created-by {
        //color:$mainColor;
        font-family: $fontBlack;
    }

    label.red {
        color:$error;
        font-weight:700;
    }

    label.green {
        color:$success;
        font-weight:700;
    }

    label.orange {
        color:$mainColor;
        font-weight:700;
    }

    @media screen and (max-width:768px) {
        label {
            font-size:15px;
        }
    }
</style>